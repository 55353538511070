<template>
    <div class="animated fadeIn">
        <b-card title="User Session Log" sub-title="For monitoring of user session logs per company">
            <b-container class="mt-4">
                <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
                <b-card>
                    <b-row>
                        <b-col md="12" sm="12" class="mt-1 mb-2">
                            <b>FILTER OPTIONS</b>
                        </b-col>
                    </b-row>

                    <b-row no-gutters>
                        <b-col lg="4" md="6" sm="12" class="mr-2">
                            <b-form-group label="Date From">
                                <b-form-datepicker v-model="filterBy.dateFrom" locale="en" reset-button
                                    label-reset-button="Clear" :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                        weekday: 'short',
                                    }" :date-disabled-fn="dateFromDisabled" />
                            </b-form-group>
                        </b-col>
                        <b-col lg="4" md="6" sm="12" class="mr-2">
                            <b-form-group label="Date To">
                                <b-form-datepicker v-model="filterBy.dateTo" locale="en" reset-button
                                    label-reset-button="Clear" :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                        weekday: 'short',
                                    }" :date-disabled-fn="dateFromDisabled" />
                            </b-form-group>
                        </b-col>
                        <b-col lg="4" md="6" sm="12" class="mr-2">
                            <b-form-group label="Company">
                                <v-select class="style-chooser" label="text" :options="companyOptions"
                                    :reduce="(company) => company.value" v-model="filterBy.company">
                                    <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                            No results found for
                                            <em>
                                                <strong>{{ search }}</strong>
                                            </em>
                                        </template>
                                        <em :style="{ opacity: 0.5 }" v-else>
                                            Start typing to search for a company
                                        </em>
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="4" md="6" sm="12" class="mr-2">
                            <b-form-group label="Device Type">
                                <b-form-select v-model="filterBy.deviceType" :options="deviceTypeOptions" class="mr-2" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row no-gutters>
                        <b-col sm="12">
                            <b-button class="mr-1" variant="success" @click="onFilterRequest">
                                Search
                            </b-button>
                            <b-button class="mr-1" variant="primary" @click="resetFilters">
                                Reset
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>

                <!-- Select Actions and Items Per Page Options -->
                <b-row>
                    <b-col sm="6" md="3" class="mt-4 mb-2">
                        <b-dropdown text=" Select Actions " variant="dark" slot="append">
                            <b-dropdown-item>
                                <json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
                                    Export User Session Logs in Excel
                                </json-excel>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
                                    Export User Session Logs to CSV
                                </json-excel>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>

                    <b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
                        <b-input-group prepend="Show" append="/ Page">
                            <b-form-select :options="pageOptions" v-model="perPage" />
                        </b-input-group>
                    </b-col>
                </b-row>

                <b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
                    :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection" responsive>
                </b-table>

                <b-row>
                    <b-col md="8" sm="12" class="my-1">
                        <span class="totalDisplay">Total: {{ totalRows }}</span>
                    </b-col>
                    <b-col md="4" sm="12" class="my-1">
                        <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                            class="my-0" />
                    </b-col>
                </b-row>
            </b-container>
        </b-card>
    </div>
</template>

<script>
// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { DateUtil } from '@/utils/dateutil';

// Database
import sessionDAO from '@/database/userSessions';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
    name: 'user-session-logs',
    components: {
        Loading,
        JsonExcel,
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'firstName',
                    sortable: true,
                },
                {
                    key: 'lastName',
                    sortable: true,
                },
                {
                    key: 'emailAddress',
                    sortable: true,
                },
                {
                    key: 'Date Logged In',
                    sortable: true,
                },
                {
                    key: 'Date Logged Out',
                    sortable: true,
                },
                {
                    key: 'deviceType',
                    sortable: true,
                }
            ],
            currentPage: 1,
            perPage: 10,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            companyOptions: [],
            deviceTypeOptions: config.deviceTypeOptions,

            allCompaniesObj: {},
            allUserSessionsObj: {},

            defaultFilterBy: {
                dateFrom: moment().format('YYYY-MM-DD'),
                dateTo: moment().format('YYYY-MM-DD'),
                company: Object.assign({}, config.companyDefaultValue),
                deviceType: null
            },
            filterBy: {
                dateFrom: moment().format('YYYY-MM-DD'),
                dateTo: moment().format('YYYY-MM-DD'),
                company: Object.assign({}, config.companyDefaultValue),
                deviceType: null
            },
            prevFilter: {},

            isSuperAdmin: this.$store.getters.isSuperAdmin,
            loggedUserCompany: this.$store.getters.loggedUserCompany,
            loggedUser: this.$store.getters.loggedUser,
            // Check for loader
            isLoading: false,
        };
    },
    computed: {
        /**
         * Returns the set of data to be included in the export. For now this just
         * returns the data as is.
         *
         * @returns {Array} the set of data to be included in the export.
         */
        exportData() {
            return this.items;
        },

        /**
         * Derives the field information based from the data table configuration.
         *
         * @returns {object} the fields to be included in the export.
         */
        exportFields() {
            return {
                'First Name': 'firstName',
                'Last Name': 'lastName',
                'Email Address': 'emailAddress',
                'Device Type': 'deviceType',
                'Date Logged In': 'Date Logged In',
                'Date Logged Out': 'Date Logged Out',
            };
        },

        fileName() {
            let currTimeStamp = DateUtil.getCurrentTimestamp();
            return (
                'UserSessionLogs-' +
                DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp)
            );
        },
    },
    mounted() {
        setTimeout(async () => {
            try {
                // Don't initiate data retrieval when the account is not authenticated
                if (!this.$store.getters.isAuthenticated) {
                    return;
                }

                // show loading indicator
                this.isLoading = true;

                // Load initial data
                this.allCompaniesObj = this.$store.getters.companies;
                this.companyOptions = DropDownItemsUtil.retrieveCompanyItems(
                    this.allCompaniesObj, this.loggedUserCompany
                );

                await this.retrieveData();
            } catch (error) {
                this.$toaster.error(
                    'Error loading data. Please reload the page again.'
                );
            }

            // hide loading indicator
            this.isLoading = false;
        }, config.timeout);
    },
    methods: {
        updateTable(userSessionsObj) {
            if (!_.isEmpty(userSessionsObj)) {
                this.allUserSessionsObj[userSessionsObj.id] =
                    userSessionsObj;
                this.filterTableContent();
            }
        },
        filterTableContent() {
            let filteredObj = Object.assign({}, this.allUserSessionsObj);

            _.forEach(this.allUserSessionsObj, (userSession, id) => {
                if (
                    this.filterBy.company.id !== null &&
                    this.filterBy.company.id !== userSession.companyId
                ) {
                    delete filteredObj[id];
                }
            });

            this.processUserSessions(filteredObj);
        },

        async retrieveData() {
            try {
                // Show loader
                this.isLoading = true;

                let filter = Object.assign({}, this.filterBy);
                filter.fromTimestamp = DateUtil.startDateTimeStamp(
                    new Date(filter.dateFrom)
                );
                filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));

                let results = await sessionDAO.getUserSessions(
                    filter
                );

                if (results.isSuccess) {
                    this.allUserSessionsObj = results.userSessions;

                } else {
                    this.allUserSessionsObj = {};
                    this.$toaster.error(
                        'Error loading data. Please reload the page again.'
                    );
                }
                this.processUserSessions(this.allUserSessionsObj);

            } catch (error) {
                this.$toaster.error(
                    'Error loading data. Please reload the page again.'
                );
            }

            // hide loading indicator
            this.isLoading = false;
        },

        processUserSessions(userSessions) {
            this.allUserSessionsObj = userSessions;

            this.items = Object.values(this.allUserSessionsObj);
            this.items.forEach((item) => {
                item['Date Logged In'] = this.getFormattedDateWithTime(item.dateLoggedIn);
                item['Date Logged Out'] = this.getFormattedDateWithTime(item.dateLoggedOut);
            });
            this.items = _.sortBy(this.items, ['dateLoggedIn']);
            this.totalRows = this.items.length;
        },


        dateFromDisabled(_ymd, date) {
            return date > new Date();
        },

        onFilterRequest() {
            if (!_.isEqual(this.filterBy, this.prevFilter)) {
                this.retrieveData();
                this.prevFilter = Object.assign({}, this.filterBy);
            }
        },

        resetFilters() {
            if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
                // reset to default
                this.filterBy = Object.assign({}, this.defaultFilterBy);
                this.retrieveData();
                this.prevFilter = Object.assign({}, this.filterBy);
            }
        },

        getFormattedDateWithTime(date) {
            if (date) {
                return DateUtil.getFormattedDateWithTime(date);
            }
            return "-";
        },
    },
};
</script>

<style scoped>
.filter {
    background-color: white;
    border-color: #122c91;
}

.filter:hover {
    background-color: #122c91;
    color: white;
}
</style>
