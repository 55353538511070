// Others
import { db } from '@/config/firebase';


function generateQuery(filter) {
    let query = db.collection("userSessions");

    if (filter.fromTimestamp && filter.fromTimestamp > 0) {
        query = query.where("dateLoggedIn", ">=", filter.fromTimestamp);
    }

    if (filter.toTimestamp && filter.toTimestamp > 0) {
        query = query.where("dateLoggedIn", "<=", filter.toTimestamp);
    }

    if (filter.company && filter.company.id && filter.company.id.length > 0) {
        query = query.where("companyId", "==", filter.company.id);
    }

    if (filter.deviceType && filter.deviceType.length > 0) {
        query = query.where("deviceType", "==", filter.deviceType);
    }

    return query;
}

async function getUserSessions(filterBy) {
    let queryRef = generateQuery(filterBy);

    return queryRef.get().then(function (querySnapshot) {
        let userSessions = {}
        querySnapshot.forEach(function (doc) {
            let userSession = doc.data();
            userSession['id'] = doc.id;
            userSessions[doc.id] = userSession;
        });
        return {
            isSuccess: true,
            userSessions: userSessions
        };
    }).catch((error) => {
        return {
            isSuccess: false,
            error: error
        };
    })
}

export default {
    getUserSessions
}